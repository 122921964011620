import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { playerAction } from './playerSlice';

import * as playerApiLib from '../lib/playerApi';

function* newsList({ payload }) {
  try {
    const newsList = yield call(playerApiLib.newsList, payload);

    yield put(playerAction.newsListSuccess({ newsList }));
  } catch (err) {
    yield put(playerAction.newsListFailure('news List Error'));
  }
}

function* eventList({ payload }) {
  try {
    const eventList = yield call(playerApiLib.eventList, payload);

    yield put(playerAction.eventListSuccess({ eventList }));
  } catch (err) {
    yield put(playerAction.eventListFailure('event List Error'));
  }
}

export function* watchNewsList() {
  yield takeLatest(playerAction.newsList, newsList);
}

export function* watchEventList() {
  yield takeLatest(playerAction.eventList, eventList);
}

function* rootSaga() {
  yield all([fork(watchNewsList), fork(watchEventList)]);
}

export default rootSaga;
