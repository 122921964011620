import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { newsAction } from './newsSlice';

import * as newsApiLib from '../lib/newsApi';

function* newsList({ payload }) {
  try {
    const newsList = yield call(newsApiLib.list, payload);

    yield put(newsAction.newsListSuccess({ newsList }));
  } catch (err) {
    yield put(newsAction.newsListFailure('news List Error'));
  }
}

function* newsDetail({ payload }) {
  try {
    const newsInfo = yield call(newsApiLib.detail, payload);

    yield put(newsAction.newsDetailSuccess({ newsInfo }));
  } catch (err) {
    yield put(newsAction.newsDetailFailure('news Detail Error'));
  }
}

function* newsAdd({ payload }) {
  try {
    const newsInfo = yield call(newsApiLib.add, payload);

    yield put(newsAction.newsAddSuccess({ newsInfo }));
  } catch (err) {
    yield put(newsAction.newsAddFailure('news Add Error'));
  }
}

function* newsEdit({ payload }) {
  try {
    const newsInfo = yield call(newsApiLib.edit, payload);

    yield put(newsAction.newsEditSuccess({ newsInfo }));
  } catch (err) {
    yield put(newsAction.newsEditFailure('news Edit Error'));
  }
}

export function* watchNewsList() {
  yield takeLatest(newsAction.newsList, newsList);
}

export function* watchNewsDetail() {
  yield takeLatest(newsAction.newsDetail, newsDetail);
}

export function* watchNewsAdd() {
  yield takeLatest(newsAction.newsAdd, newsAdd);
}

export function* watchNewsEdit() {
  yield takeLatest(newsAction.newsEdit, newsEdit);
}

function* rootSaga() {
  yield all([
    fork(watchNewsList),
    fork(watchNewsDetail),
    fork(watchNewsAdd),
    fork(watchNewsEdit),
  ]);
}

export default rootSaga;
