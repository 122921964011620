import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface NewsPayload {
  newsId: string;
}

interface NewsInfo {
  newsId: string;
}

interface NewsState {
  newsInfo: NewsInfo;
  newsList: Array<NewsInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const newsInitialState: NewsState = {
  newsInfo: {},
  newsList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  newsList: (state: NewsState, { payload }: PayloadAction<NewsPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  newsListSuccess: (
    state: NewsState,
    { payload }: PayloadAction<NewsState>,
  ) => {
    state.newsList = payload.newsList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  newsListFailure: (state: NewsState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  newsDetail: (state: NewsState, { payload }: PayloadAction<NewsPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  newsDetailSuccess: (
    state: NewsState,
    { payload }: PayloadAction<NewsState>,
  ) => {
    state.newsInfo = payload.newsInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  newsDetailFailure: (state: NewsState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  newsAdd: (state: NewsState, { payload }: PayloadAction<NewsPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  newsAddSuccess: (state: NewsState, { payload }: PayloadAction<NewsState>) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  newsAddFailure: (state: NewsState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  newsEdit: (state: NewsState, { payload }: PayloadAction<NewsPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  newsEditSuccess: (
    state: NewsState,
    { payload }: PayloadAction<NewsState>,
  ) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  newsEditFailure: (state: NewsState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'news',
  initialState: newsInitialState,
  reducers: reducers,
});

const selectNewsList = createDraftSafeSelector(
  (state: NewsState) => state.newsList,
  newsList => newsList,
);

const selectNewsInfo = createDraftSafeSelector(
  (state: NewsState) => state.newsInfo,
  newsInfo => newsInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: NewsState) => state.actionResult,
  (state: NewsState) => state.isLoading,
  (state: NewsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const newsSelector = {
  newsList: state => selectNewsList(state[NEWS]),
  newsInfo: state => selectNewsInfo(state[NEWS]),
  status: state => selectStatus(state[NEWS]),
};

export const NEWS = slice.name;
export const newsReducer = slice.reducer;
export const newsAction = slice.actions;
