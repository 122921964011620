import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface PlayerPayload {
  deviceId: string;
}

interface NewsInfo {
  newsId: string;
}

interface EventInfo {
  eventId: string;
}

interface PlayerState {
  newsList: Array<NewsInfo>;
  eventList: Array<EventInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const playerInitialState: PlayerState = {
  newsList: [],
  eventList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  newsList: (state: PlayerState, { payload }: PayloadAction<PlayerPayload>) => {
    state.actionResult = 'NEWS_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  newsListSuccess: (
    state: PlayerState,
    { payload }: PayloadAction<PlayerState>,
  ) => {
    state.newsList = payload.newsList;
    state.actionResult = 'NEWS_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  newsListFailure: (state: PlayerState, action: PayloadAction<string>) => {
    state.actionResult = 'NEWS_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  eventList: (
    state: PlayerState,
    { payload }: PayloadAction<PlayerPayload>,
  ) => {
    state.actionResult = 'EVENT_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  eventListSuccess: (
    state: PlayerState,
    { payload }: PayloadAction<PlayerState>,
  ) => {
    state.eventList = payload.eventList;
    state.actionResult = 'EVENT_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  eventListFailure: (state: PlayerState, action: PayloadAction<string>) => {
    state.actionResult = 'EVENT_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'player',
  initialState: playerInitialState,
  reducers: reducers,
});

const selectNewsList = createDraftSafeSelector(
  (state: PlayerState) => state.newsList,
  newsList => newsList,
);

const selectEventList = createDraftSafeSelector(
  (state: PlayerState) => state.eventList,
  eventList => eventList,
);

const selectStatus = createDraftSafeSelector(
  (state: PlayerState) => state.actionResult,
  (state: PlayerState) => state.isLoading,
  (state: PlayerState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const playerSelector = {
  newsList: state => selectNewsList(state[PLAYER]),
  eventList: state => selectEventList(state[PLAYER]),
  status: state => selectStatus(state[PLAYER]),
};

export const PLAYER = slice.name;
export const playerReducer = slice.reducer;
export const playerAction = slice.actions;
