import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { eventAction } from './eventSlice';

import * as eventApiLib from '../lib/eventApi';

function* eventList({ payload }) {
  try {
    const eventList = yield call(eventApiLib.list, payload);

    yield put(eventAction.eventListSuccess({ eventList }));
  } catch (err) {
    yield put(eventAction.eventListFailure('event List Error'));
  }
}

function* eventDetail({ payload }) {
  try {
    const eventInfo = yield call(eventApiLib.detail, payload);

    yield put(eventAction.eventDetailSuccess({ eventInfo }));
  } catch (err) {
    yield put(eventAction.eventDetailFailure('event Detail Error'));
  }
}

function* eventAdd({ payload }) {
  try {
    const eventInfo = yield call(eventApiLib.add, payload);

    yield put(eventAction.eventAddSuccess({ eventInfo }));
  } catch (err) {
    yield put(eventAction.eventAddFailure('event Add Error'));
  }
}

function* eventEdit({ payload }) {
  try {
    const eventInfo = yield call(eventApiLib.edit, payload);

    yield put(eventAction.eventEditSuccess({ eventInfo }));
  } catch (err) {
    yield put(eventAction.eventEditFailure('event Edit Error'));
  }
}

function* eventRemove({ payload }) {
  try {
    yield call(eventApiLib.remove, payload);

    yield put(eventAction.eventRemoveSuccess());
  } catch (err) {
    yield put(eventAction.eventRemoveFailure('event Remove Error'));
  }
}

export function* watchEventList() {
  yield takeLatest(eventAction.eventList, eventList);
}

export function* watchEventDetail() {
  yield takeLatest(eventAction.eventDetail, eventDetail);
}

export function* watchEventAdd() {
  yield takeLatest(eventAction.eventAdd, eventAdd);
}

export function* watchEventEdit() {
  yield takeLatest(eventAction.eventEdit, eventEdit);
}

export function* watchEventRemove() {
  yield takeLatest(eventAction.eventRemove, eventRemove);
}

function* rootSaga() {
  yield all([
    fork(watchEventList),
    fork(watchEventDetail),
    fork(watchEventAdd),
    fork(watchEventEdit),
    fork(watchEventRemove),
  ]);
}

export default rootSaga;
