import * as apiLib from './api';

export const list = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchApi('/event/list', data);
    if (result.resultFlag) {
      return result.eventList;
    } else {
      throw Object.assign(new Error('event list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ eventId }) => {
  try {
    const data = {
      eventId,
    };

    const result = await apiLib.fetchApi('/event/detail', data);
    if (result.resultFlag) {
      return result.eventInfo;
    } else {
      throw Object.assign(new Error('event detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({
  eventTitle,
  eventDetail,
  eventPlace,
  eventDt,
  startDt,
  endDt,
  logoFile,
}) => {
  try {
    const uploadData = new FormData();
    uploadData.append('eventTitle', eventTitle);
    uploadData.append('eventDetail', eventDetail);
    uploadData.append('eventPlace', eventPlace);
    uploadData.append('eventDt', eventDt);
    uploadData.append('startDt', startDt);
    uploadData.append('endDt', endDt);
    if (logoFile) {
      uploadData.append('logoFile', logoFile, logoFile.name);
    }

    const result = await apiLib.fetchMultipartApi('/event/add', uploadData);
    if (result.resultFlag) {
      return result.eventInfo;
    } else {
      throw Object.assign(new Error('event add error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const edit = async ({
  eventId,
  eventTitle,
  eventDetail,
  eventPlace,
  eventDt,
  startDt,
  endDt,
  logoFile,
}) => {
  try {
    const uploadData = new FormData();
    uploadData.append('eventId', eventId);
    uploadData.append('eventTitle', eventTitle);
    uploadData.append('eventDetail', eventDetail);
    uploadData.append('eventPlace', eventPlace);
    uploadData.append('eventDt', eventDt);
    uploadData.append('startDt', startDt);
    uploadData.append('endDt', endDt);
    if (logoFile) {
      uploadData.append('logoFile', logoFile, logoFile.name);
    }

    const result = await apiLib.fetchMultipartApi('/event/edit', uploadData);
    if (result.resultFlag) {
      return result.eventInfo;
    } else {
      throw Object.assign(new Error('event edit error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const remove = async ({ eventId }) => {
  try {
    const data = {
      eventId,
    };

    const result = await apiLib.fetchApi('/event/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('event remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
