import { combineReducers } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { AUTH, authReducer } from './authSlice';
import { NEWS, newsReducer } from './newsSlice';
import { EVENT, eventReducer } from './eventSlice';
import { PLAYER, playerReducer } from './playerSlice';
import authSaga from './authSaga';
import newsSaga from './newsSaga';
import eventSaga from './eventSaga';
import playerSaga from './playerSaga';

const createRootReducer = () =>
  combineReducers({
    [AUTH]: authReducer,
    [NEWS]: newsReducer,
    [EVENT]: eventReducer,
    [PLAYER]: playerReducer,
  });

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([authSaga(), newsSaga(), eventSaga(), playerSaga()]);
}

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: true,
    middleware: [sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
