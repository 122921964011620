import React from 'react';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import AuthLayout from '../pages/layouts/Auth';
import ManagerLayout from '../pages/layouts/Manager';
import PlayerLayout from '../pages/layouts/Player';

const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Sso = React.lazy(() => import('../pages/auth/Sso'));

const NewsPlaylist = React.lazy(() => import('../pages/news/NewsPlaylist'));

const EventList = React.lazy(() => import('../pages/event/EventList'));
const EventAdd = React.lazy(() => import('../pages/event/EventAdd'));
const EventEdit = React.lazy(() => import('../pages/event/EventEdit'));

const Background = React.lazy(() => import('../pages/player/Background'));

const Routes = () => {
  const authRoutes: RouteObject = {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/auth/login" />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  };

  const ssoRoutes: RouteObject = {
    path: '/access_key',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Sso />,
      },
    ],
  };

  const rootRoutes: RouteObject = {
    path: '/',
    element: <ManagerLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/news/playlist" />,
      },
    ],
  };

  const newsRoutes: RouteObject = {
    path: '/news',
    element: <ManagerLayout />,
    children: [
      {
        path: 'playlist',
        element: <NewsPlaylist />,
      },
    ],
  };

  const eventRoutes: RouteObject = {
    path: '/event',
    element: <ManagerLayout />,
    children: [
      {
        path: 'list',
        element: <EventList />,
      },
      {
        path: 'add',
        element: <EventAdd />,
      },
      {
        path: 'edit',
        element: <EventEdit />,
      },
    ],
  };

  const playerRoutes: RouteObject = {
    path: '/player',
    element: <PlayerLayout />,
    children: [
      {
        index: true,
        element: <Background />,
      },
    ],
  };

  const noMatchRoutes: RouteObject = {
    path: '*',
    element: <Navigate to="/" />,
  };

  const routes: RouteObject[] = [
    authRoutes,
    ssoRoutes,
    rootRoutes,
    newsRoutes,
    eventRoutes,
    playerRoutes,
    noMatchRoutes,
  ];

  return useRoutes(routes);
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default React.memo(Router);
