import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface EventPayload {
  eventId: string;
}

interface EventInfo {
  eventId: string;
}

interface EventState {
  eventInfo: EventInfo;
  eventList: Array<EventInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const eventInitialState: EventState = {
  eventInfo: {},
  eventList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  eventList: (state: EventState, { payload }: PayloadAction<EventPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  eventListSuccess: (
    state: EventState,
    { payload }: PayloadAction<EventState>,
  ) => {
    state.eventList = payload.eventList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  eventListFailure: (state: EventState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  eventDetail: (
    state: EventState,
    { payload }: PayloadAction<EventPayload>,
  ) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  eventDetailSuccess: (
    state: EventState,
    { payload }: PayloadAction<EventState>,
  ) => {
    state.eventInfo = payload.eventInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  eventDetailFailure: (state: EventState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  eventAdd: (state: EventState, { payload }: PayloadAction<EventPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  eventAddSuccess: (
    state: EventState,
    { payload }: PayloadAction<EventState>,
  ) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  eventAddFailure: (state: EventState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  eventEdit: (state: EventState, { payload }: PayloadAction<EventPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  eventEditSuccess: (
    state: EventState,
    { payload }: PayloadAction<EventState>,
  ) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  eventEditFailure: (state: EventState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  eventRemove: (
    state: EventState,
    { payload }: PayloadAction<EventPayload>,
  ) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  eventRemoveSuccess: (
    state: EventState,
    { payload }: PayloadAction<EventState>,
  ) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  eventRemoveFailure: (state: EventState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'event',
  initialState: eventInitialState,
  reducers: reducers,
});

const selectEventList = createDraftSafeSelector(
  (state: EventState) => state.eventList,
  eventList => eventList,
);

const selectEventInfo = createDraftSafeSelector(
  (state: EventState) => state.eventInfo,
  eventInfo => eventInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: EventState) => state.actionResult,
  (state: EventState) => state.isLoading,
  (state: EventState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const eventSelector = {
  eventList: state => selectEventList(state[EVENT]),
  eventInfo: state => selectEventInfo(state[EVENT]),
  status: state => selectStatus(state[EVENT]),
};

export const EVENT = slice.name;
export const eventReducer = slice.reducer;
export const eventAction = slice.actions;
