import * as apiLib from './api';

export const list = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchApi('/news/list', data);
    if (result.resultFlag) {
      return result.newsList;
    } else {
      throw Object.assign(new Error('news list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ newsId }) => {
  try {
    const data = {
      newsId,
    };

    const result = await apiLib.fetchApi('/news/detail', data);
    if (result.resultFlag) {
      return result.newsInfo;
    } else {
      throw Object.assign(new Error('news detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({
  newsTitle,
  newsSubTitle,
  newsFile,
  eventTitle,
  eventSubTitle,
  eventFile,
}) => {
  try {
    const uploadData = new FormData();
    uploadData.append('newsTitle', newsTitle);
    uploadData.append('newsSubTitle', newsSubTitle);
    if (newsFile) {
      uploadData.append('newsFile', newsFile, newsFile.name);
    }
    uploadData.append('eventTitle', eventTitle);
    uploadData.append('eventSubTitle', eventSubTitle);
    if (eventFile) {
      uploadData.append('eventFile', eventFile, eventFile.name);
    }

    const result = await apiLib.fetchMultipartApi('/news/add', uploadData);
    if (result.resultFlag) {
      return result.newsInfo;
    } else {
      throw Object.assign(new Error('news add error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const edit = async ({
  newsId,
  newsTitle,
  newsSubTitle,
  newsFile,
  eventTitle,
  eventSubTitle,
  eventFile,
}) => {
  try {
    const uploadData = new FormData();
    uploadData.append('newsId', newsId);
    uploadData.append('newsTitle', newsTitle);
    uploadData.append('newsSubTitle', newsSubTitle);
    if (newsFile) {
      uploadData.append('newsFile', newsFile, newsFile.name);
    }
    uploadData.append('eventTitle', eventTitle);
    uploadData.append('eventSubTitle', eventSubTitle);
    if (eventFile) {
      uploadData.append('eventFile', eventFile, eventFile.name);
    }

    const result = await apiLib.fetchMultipartApi('/news/edit', uploadData);
    if (result.resultFlag) {
      return result.newsInfo;
    } else {
      throw Object.assign(new Error('news edit error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
