import * as apiLib from './api';

export const newsList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchApi('/player/news-list', data);
    if (result.resultFlag) {
      return result.newsList;
    } else {
      throw Object.assign(new Error('news list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const eventList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchApi('/player/event-list', data);
    if (result.resultFlag) {
      return result.eventList;
    } else {
      throw Object.assign(new Error('event list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
